<template>
    <div>
      <div class="d-flex justify-content-center align-items-center flex-nowrap mb-1">
        <div class="
                  d-flex
                  justify-content-center
                  align-items-center
                  flex-nowrap
                  mr-5
                ">
          <b-form-input id="nameFilter" v-model="nameFilter"
            placeholder="Search by managing hq name" />
        </div>
      </div>

      <div class="d-flex justify-content-center align-items-center flex-nowrap mb-1">
        <div class="
                  d-flex
                  justify-content-center
                  align-items-center
                  flex-nowrap
                  mr-5
                ">
          <b-button variant="primary" pill @click="filter">
            <feather-icon icon="SearchIcon" class="mr-50" />
            <span class="align-middle">Search</span>
          </b-button>
        </div>
  
        <div class="
                  d-flex
                  justify-content-center
                  align-items-center
                  flex-nowrap
                  mr-5
                ">
          <b-button variant="primary" pill @click="search">
            <feather-icon icon="RefreshCwIcon" class="mr-50" />
            <span class="align-middle">Refresh</span>
          </b-button>
        </div>
  
        <div class="text-right">
          <b-button variant="primary" pill @click="createManagingHq"
            v-if="hasPermission('managing_hq_create')">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Create</span>
          </b-button>
        </div>
      </div>

      <b-card>
        <b-table responsive :fields="fields" :items="managingHqs" details-td-class="m-0 p-0" small
          v-if="hasPermission('managing_hq_show')" :per-page="0" :busy="isBusy">
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner :variant="'primary'" class="align-middle"></b-spinner>
            </div>
          </template>
          <template #cell(created_by_data)="row">
            {{ row.item.created_by_data ? row.item.created_by_data.user : "" }}
          </template>
          <template #cell(updated_by_data)="row">
            {{ row.item.updated_by_data ? row.item.updated_by_data.user : "" }}
          </template>
          <template #cell(manage)="row">
           <div class="d-flex align-items-center">
            <b-button variant="info" pill size="sm" class="mr-1" @click="viewManagingHq(row.item)"
              v-if="hasPermission('managing_hq_read')">
              View
            </b-button>
            <template>
              <b-button variant="info" pill size="sm" class="mr-1" @click="editManagingHq(row.item)"
                v-if="hasPermission('managing_hq_update')">
                Edit
              </b-button>
            </template>
            <template v-if="!row.item.assigned">
              <b-button variant="danger" pill size="sm" class="mr-1" @click="deleteManagingHq(row.item)"
                v-if="hasPermission('managing_hq_delete')">
                Delete
              </b-button>
            </template>
            </div>
          </template>
        </b-table>
        <b-row>
                <b-col md="2">
                    <div style="float:left">
                        <h5 style="margin: 0; display: inline;" class="text-primary">Count: </h5>
                        <h5 style="margin: 0; display: inline;" align="right">
                            <strong>
                                {{totalDataLength}}
                            </strong>
                        </h5>
                    </div>
                </b-col>
            </b-row>
  
        <div class="d-flex justify-content-center align-items-center flex-nowrap m-2">
          <b-pagination size="md" :total-rows="totalItems" v-model="currentPage" :per-page="perPage"></b-pagination>
        </div>
      </b-card>
      <managing-hq-create-modal :managingHq="managingHq" @modalClosed="onModalClosed"
        :showModal="visible" :key="`create-${managingHqCreateModalCount}`" />
      <managing-hq-edit-modal :managingHq="managingHq" :allowEdit="allowEdit" @modalClosed="onModalClosed"
        :showModal="visible" :key="`edit-${managingHqEditModalCount}`" />
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import util from "@/util.js";
  import ManagingHqCreateModal from "@/components/booking/admin/ManagingHqCreateModal.vue";
  import ManagingHqEditModal from "@/components/booking/admin/ManagingHqEditModal.vue";
//   import ManagingHqViewModal from "@/components/booking/ManagingHqViewModal.vue";
  
  export default {
    components: {
      ManagingHqCreateModal,
      ManagingHqEditModal,
    //   ManagingHqViewModal,
    },
    data() {
      return {
        fields: [
          { key: "manage", label: "Manage" },
        //   { key: "id", label: "ManagingHq ID" },
          { key: "name", label: "Managing HQ Name" },
          { key: "created_at", label: "Created At" },
          { key: "created_by_data", label: "Created By" },
          { key: "updated_at", label: "Last updated" },
          { key: "updated_by_data", label: "Updated By" },
        ],
        currentPage: 1,
        perPage: 0,
        totalItems: 0,
        managingHq: null,
        managingHqs: [],
        managingHqCreateModalCount: 0,
        managingHqEditModalCount: 0,
        totalDataLength: 0,
        visible: false,
        nameFilter: "",
        isBusy: false,
        allowEdit: true,
      };
    },
    mixins: [util],
    async mounted() {
      // await this.search();
    },
    methods: {
      ...mapActions({
        getManagingHqs: "appData/getManagingHqs",
        updateManagingHq: "appData/updateManagingHq",
        delete: "appData/deleteManagingHq",
        // updateManagingHqAttribute: "appData/updateManagingHqAttribute"
      }),
      async filter() {
        this.currentPage = 1;
        await this.search();
      },
      async search() {
        this.isBusy = true;
        await this.fetchPaginatedData();
        this.isBusy = false;
      },
      createManagingHq() {
          this.managingHqCreateModalCount += 1;
          this.visible = true;
          this.$nextTick(() => {
            this.$bvModal.show("managing-hq-create-modal");
          });
      },
      editManagingHq(managingHq) {
        this.managingHq = managingHq;
        this.managingHqEditModalCount += 1;
        this.visible = true;
        this.allowEdit = true;
        this.$nextTick(() => {
          this.$bvModal.show("managing-hq-edit-modal");
        });
      },
      viewManagingHq(managingHq) {
        this.managingHq = managingHq;
        this.managingHqEditModalCount += 1;
        this.visible = true;
        this.allowEdit = false;
        this.$nextTick(() => {
            this.$bvModal.show("managing-hq-edit-modal");
        });
      },

      //HARD DELETE
      async deleteManagingHq(managingHq) {
        try {
          this.$swal({
            title:
              "Are you sure?",
            icon: "warning",
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: "Confirm",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-danger ml-1",
            },
            buttonsStyling: false,
          }).then(async (result) => {
            if (result.value) {
              try {
                const res = await this.delete({
                  pk: managingHq.id,
                });
                if (res.status === 204) {
                  this.$swal({
                    title: "Managing HQ deleted successfully",
                    icon: "success",
                  });
                  this.$nextTick(() => {
                    this.search();
                  });
                  this.$emit("modalClosed");
                }
              } catch (error) {
                this.show = false;
                if (error.response.status == 500) {
                  // this.displayError("Kindly make sure this managing hq does not have any dependencies");
                  this.$swal({
                    title: "Kindly make sure this managing hq does not have any dependencies",
                    icon: "error",
                  });
                }
                else {
                  this.displayError(error);
                }
              }
            } else {
              this.show = false;
            }
          });
        } catch (error) {
          this.displayError(error);
        }
      },

      // SOFT DELETE
      // deleteManagingHq(managingHq) {
      //   try {
      //     this.$swal({
      //       title:
      //         "Are you sure? This managing hq will be deleted.",
      //       icon: "warning",
      //       showCloseButton: true,
      //       showCancelButton: true,
      //       confirmButtonText: "Confirm",
      //       customClass: {
      //         confirmButton: "btn btn-primary",
      //         cancelButton: "btn btn-danger ml-1",
      //       },
      //       buttonsStyling: false,
      //     }).then(async (result) => {
      //       if (result.value) {
      //         try {
      //           const res = await this.updateManagingHq({
      //             payload: {
      //               is_delete: true,
      //               updated_by: this.getLoggedInUser.id,
      //             },
      //             pk: managingHq.id,
      //           });
      //           let response_string = "ManagingHq deleted successfully";
      //           let response_msg = response_string;
      //           if (res.data.response_msg) {
      //             response_msg = response_msg.concat(res.data.response_msg);
      //           }
      //           if (res.status === 200) {
      //             this.$swal({
      //               title: response_msg,
      //               icon: "success",
      //             });
      //             this.$nextTick(() => {
      //               this.fetchPaginatedData();
      //             });
      //             this.$emit("modalClosed");
      //           }
      //         } catch (error) {
      //           this.show = false;
      //           this.displayError(error);
      //         }
      //       } else {
      //         this.show = false;
      //       }
      //     });
      //   } catch (error) {
      //     this.displayError(error);
      //   }
      // },

      async onModalClosed() {
        await this.fetchPaginatedData();
        this.visible = false;
      },
      async fetchPaginatedData() {
        try {
          let tempParams = {
            pageNumber: this.currentPage,
          };
          if (this.nameFilter) {
            tempParams["name"] = this.nameFilter;
          }
          const res = await this.getManagingHqs(tempParams);
          this.managingHqs = res.data.results;
          
          this.totalItems = res.data.count;
          this.perPage = res.data.per_page;
          this.totalDataLength = res.data.count;
        } catch (error) {
          this.displayError(error);
        }
      },
    },
    computed: {
      ...mapGetters({
        hasPermission: "appData/hasPermission",
        getLoggedInUser: "appData/getLoggedInUser",
      }),
    },
    watch: {
      currentPage: async function (val) {
        await this.fetchPaginatedData();
      },
    },
  };
  </script>
  
  <style></style>
  